import * as httpDittoProject from "@/http/dittoProject";
import * as httpVariant from "@/http/variantTyped";
import { clearSelectionActionAtom } from "@/stores/ProjectSelection";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import batchedAsyncAtomFamily from "@shared/frontend/stores/batchedAsyncAtomFamily";
import { IFDittoProjectBlockUpdate } from "@shared/types/DittoProject";
import { ITextItemPopulatedComments } from "@shared/types/TextItem";
import { IFDittoBlockData, IFDittoProjectData } from "@shared/types/http/DittoProject";
import { atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { splitAtom, unwrap } from "jotai/utils";
import { searchAtom, selectedFiltersAtomFamily } from "./ProjectFiltering";

// MARK: - Source Atoms

/**
 * The source of truth for the project Id. This atom should be set when the user
 * navigates to a project.
 */
export const projectIdAtom = atom<string | null>(null);

export const { familyAtom: textItemFamilyAtom, resetAtom: resetTextItemFamilyAtomActionAtom } =
  batchedAsyncAtomFamily<ITextItemPopulatedComments>({
    asyncFetchRequest: async (get, ids) => {
      const [request] = httpDittoProject.getTextItems({
        ids,
        projectId: get(projectIdAtom)!,
      });
      const response = await request;

      return response.data;
    },
    getId: (item) => item._id,
    debugPrefix: "Text Item",
  });

/**
 * The source of truth for block metadata. You should be fetching/updating blocks
 * through this atom to ensure that the UI updates correctly.
 *
 * Note: this is *not* the source of truth for which text items belong in which blocks! Project structure is solely
 * managed by the project atom, and each text item also has a blockId field that is used to determine which block it
 * belongs in, but that information does not live in this atom.
 *
 * This atom should be consumed like a mix between a Jotai [FamilyAtom](https://jotai.org/docs/utilities/family) and [AtomWithReset](https://jotai.org/docs/utilities/resettable#atomwithreset).
 */
export const { familyAtom: blockFamilyAtom, resetAtom: resetBlockFamilyAtomActionAtom } = batchedAsyncAtomFamily({
  asyncFetchRequest: async (get, ids) => {
    const [request] = httpDittoProject.getBlocks({
      ids,
      projectId: get(projectIdAtom)!,
    });
    const response = await request;

    return response.data;
  },
  getId: (item) => item._id,
  debugPrefix: "Block",
});

/**
 * Fetches a project by its Id.
 */
async function fetchProjectById(args: {
  projectId: string;
  projectContentSearchQuery: string;
  statuses?: string[];
  tags?: string[];
  assignees?: string[];
}) {
  const { projectId, projectContentSearchQuery, statuses, tags, assignees } = args;
  const [request] = httpDittoProject.getProject({ projectId, projectContentSearchQuery, statuses, tags, assignees });
  const { data: project } = await request;
  return project;
}

/**
 * This is the source of truth for the project. You should be fetching/deriving/updating the project
 * through this atom to ensure that the UI updates correctly.
 */
export const { valueAtom: projectAtom, refreshAtom: refreshProjectActionAtom } = asyncMutableDerivedAtom({
  async loadData(get) {
    const projectId = get(projectIdAtom);
    const statusFilters = get(selectedFiltersAtomFamily("status"));
    const tagsFilters = get(selectedFiltersAtomFamily("tags"));
    const assigneeFilters = get(selectedFiltersAtomFamily("assignee"));
    const searchValue = get(searchAtom);

    if (!projectId) throw new Error("projectIdAtom is not set");

    return await fetchProjectById({
      projectId,
      projectContentSearchQuery: searchValue,
      statuses: statusFilters ?? undefined,
      tags: tagsFilters ?? undefined,
      assignees: assigneeFilters ?? undefined,
    });
  },
  debugLabel: "Project",
});

const unwrappedProjectAtom = unwrap<Promise<IFDittoProjectData>, IFDittoProjectData>(
  projectAtom,
  (prev) =>
    prev ?? {
      _id: "",
      name: "",
      blocks: [],
      hiddenBlocksCount: 0,
      hiddenRootTextItemsCount: 0,
      hiddenTextItemsCount: 0,
      integrations: {},
      workspaceId: "",
      createdAt: new Date(),
      updatedAt: new Date(),
    }
);

async function fetchAllProjectTags(projectId: string) {
  const [request] = httpDittoProject.getAllTags({ projectId });
  const response = await request;
  return response.data;
}

export const { valueAtom: allTagsInProject } = asyncMutableDerivedAtom({
  async loadData(get) {
    const projectId = get(projectIdAtom);

    if (!projectId) throw new Error("projectIdAtom is not set");

    return await fetchAllProjectTags(projectId);
  },
  debugLabel: "Project Tags",
});

// MARK: - Derived Atoms
export const projectNameAtom = atom((get) => get(unwrappedProjectAtom).name);
export const workspaceIdAtom = atom((get) => get(unwrappedProjectAtom).workspaceId);

export const projectBlocksAtom = focusAtom(projectAtom, (optic) => optic.prop("blocks"));

export const unwrappedProjectBlocksAtom = unwrap(projectBlocksAtom, (prev) => prev ?? []);

export const nonBlockTextItemsAtom = atom((get) => {
  const blocks = get(unwrappedProjectBlocksAtom);
  return blocks.filter((block) => !block._id).flatMap((block) => block.textItems);
});

async function reorderBlocks(args: { projectId: string; blockIds: string[]; newIndex: number }) {
  const [request] = httpDittoProject.reorderBlocks({
    projectId: args.projectId,
    blockIds: args.blockIds,
    newIndex: args.newIndex,
  });
  const response = await request;
  return response.data;
}

export const reorderBlockActionAtom = atom(null, async (get, set, update: { blockId: string; newIndex: number }) => {
  const blocks = get(unwrappedProjectBlocksAtom);
  const currentIndex = blocks.findIndex((b) => b._id === update.blockId);
  const newBlocks = [...blocks];
  const [blockToMove] = newBlocks.splice(currentIndex, 1);
  newBlocks.splice(update.newIndex, 0, blockToMove);

  set(projectBlocksAtom, newBlocks);

  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");

  await reorderBlocks({
    projectId,
    blockIds: [update.blockId],
    newIndex: update.newIndex,
  });
});

function clamp(val: number, min: number, max: number) {
  return Math.max(Math.min(val, max), min);
}

export const shiftBlockOrderActionAtom = atom(
  null,
  (get, set, update: { blockId: string | null; direction: "up" | "down" }) => {
    const blocks = get(unwrappedProjectBlocksAtom);
    const currentIndex = blocks.findIndex((b) => b._id === update.blockId);

    const newIndex = clamp(currentIndex + (update.direction === "up" ? -1 : 1), 0, blocks.length - 2); // -2 because we don't want to include the "null block" of un-blocked text items
    set(reorderBlockActionAtom, {
      blockId: update.blockId,
      newIndex,
    });
  }
);

export const projectBlocksSplitAtom = splitAtom(unwrappedProjectBlocksAtom);

export const projectTextItemsCountAtom = atom((get) => {
  return get(unwrappedProjectAtom).blocks.reduce((acc, block) => acc + block.textItems.length, 0);
});

export const projectBlocksCountAtom = atom((get) => {
  return get(unwrappedProjectAtom).blocks.length;
});

export const projectHiddenResultsTextItemsCountAtom = atom((get) => {
  const blocks = get(unwrap(projectBlocksAtom, (prev) => prev ?? []));
  return blocks.reduce((acc, block) => acc + block.allTextItems.length - block.textItems.length, 0);
});

export interface INavBlockItem {
  _id: string;
  type: "block";
}

export interface INavTextItem {
  _id: string;
  type: "text";
  sortKey: string;
}

export interface INavMessageItem {
  _id: string;
  type: "message";
  message: string;
}

export function isValidBlock(block: any): block is INavBlockItem {
  return block && block._id;
}

export const flattenedProjectItemsAtom = atom((get) => {
  const project = get(unwrappedProjectAtom);
  const flattenedProjectItems = project.blocks.reduce<(INavBlockItem | INavTextItem | INavMessageItem)[]>(
    (acc, block) => {
      if (isValidBlock(block)) {
        acc.push({ _id: block._id, type: "block" });
        acc.push(...block.textItems.map((textItem) => ({ ...textItem, type: "text" as const })));
        /**
         * Add a user-friendly message that summarizes # of text items hidden from this block due to search results,
         * if applicable
         */
        const hiddenTextItemsCount = block.allTextItems.length - block.textItems.length;
        if (hiddenTextItemsCount > 0) {
          acc.push({
            _id: `${block._id}_hidden_search_results`,
            type: "message",
            message: `${hiddenTextItemsCount} text ${hiddenTextItemsCount === 1 ? "item" : "items"} not shown`,
          });
        }
      } else {
        acc.push(...block.textItems.map((textItem) => ({ ...textItem, type: "text" as const })));
      }
      return acc;
    },
    []
  );

  /**
   * Build a user-friendly message that summarizes # of blocks / root text items hidden from search results,
   * if applicable
   */
  const hiddenProjectMessageStringComponents: string[] = [];

  if (project.hiddenBlocksCount > 0) {
    hiddenProjectMessageStringComponents.push(
      `${project.hiddenBlocksCount} ${project.hiddenBlocksCount === 1 ? "block" : "blocks"}`
    );
  }
  if (project.hiddenRootTextItemsCount > 0) {
    hiddenProjectMessageStringComponents.push(
      `${project.hiddenRootTextItemsCount} text ${project.hiddenRootTextItemsCount === 1 ? "item" : "items"}`
    );
  }

  const hiddenProjectItemsMessage = hiddenProjectMessageStringComponents.length
    ? `${hiddenProjectMessageStringComponents.join(" and ")} not shown`
    : "";

  return {
    flattenedProjectItems,
    hiddenProjectItemsMessage,
  };
});

export const variantsAtom = atom(async () => {
  const [request] = httpVariant.getForWorkspace();
  const response = await request;
  return response.data;
});
// MARK: - Actions

function deleteBlocks(projectId: string, blockIds: string[]) {
  const [request] = httpDittoProject.deleteBlocks({ projectId, blockIds });
  return request;
}

export const deleteBlocksActionAtom = atom(null, async (get, set, blockIds: string[]) => {
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");

  set(clearSelectionActionAtom);

  // Update the project structure atom
  const project = get(unwrappedProjectAtom);
  const blocksToDelete = project.blocks.filter((block) => blockIds.includes(block._id ?? ""));

  // each block has two lists of text items: allTextItems and textItems
  // - allTextItems is the list of text items that are in the block, regardless of whether they are visible in filter state
  // - textItems is the list of text items in the block that are currently visible in filter state
  // we need to get *both* of these lists from each deleted block, and merge them into the "not in block" block, which
  // is the one with a null _id

  const textItemsOfDeletedBlocks = blocksToDelete.flatMap((block) => block.textItems);
  const allTextItemsOfDeletedBlocks = blocksToDelete.flatMap((block) => block.allTextItems);

  const newProjectBlocks = project.blocks.reduce<IFDittoBlockData[]>((acc, block) => {
    // merge all the text items of our deleted blocks into the "not in block" block
    if (block._id === null) {
      acc.push({
        ...block,
        textItems: block.textItems.concat(textItemsOfDeletedBlocks).sort((a, b) => a.sortKey.localeCompare(b.sortKey)),
        allTextItems: block.allTextItems
          .concat(allTextItemsOfDeletedBlocks)
          .sort((a, b) => a.sortKey.localeCompare(b.sortKey)),
      });
    } else if (!blockIds.includes(block._id)) {
      acc.push(block);
    }
    return acc;
  }, []);

  set(projectAtom, {
    ...project,
    blocks: newProjectBlocks,
  });

  // Update the block family atom -- remove the deleted blocks from the atom
  blockIds.map(blockFamilyAtom.remove);

  // Update all the text item family atoms -- each text item now needs a blockId of null
  for (const textItem of textItemsOfDeletedBlocks) {
    const textItemAtom = textItemFamilyAtom(textItem._id);
    const textItemValue = await get(textItemAtom);

    set(textItemAtom, {
      ...textItemValue,
      blockId: null,
    });
  }

  // Update on the backend
  // TODO: handle rollback/error state for updating blocks -- https://linear.app/dittowords/issue/DIT-8027/add-support-for-error-state-and-rollback-when-updating-blocks
  await deleteBlocks(projectId, blockIds);
});

async function updateBlocks(projectId: string, blocks: IFDittoProjectBlockUpdate[]) {
  const [request] = httpDittoProject.updateBlocks({ projectId, blocks });
  const response = await request;
  return response.data;
}

export const updateBlocksActionAtom = atom(null, async (get, set, blocks: IFDittoProjectBlockUpdate[]) => {
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");

  const blockUpdateMap = blocks
    .filter((block) => block._id)
    .reduce<Record<string, IFDittoProjectBlockUpdate>>((acc, block) => {
      acc[block._id] = block;
      return acc;
    }, {});

  // Update the blocks in state -- project atom
  const project = await get(projectAtom);
  const projectNew = { ...project };
  projectNew.blocks = project.blocks.map((block) => {
    if (block._id) {
      const blockUpdate = blockUpdateMap[block._id];
      if (blockUpdate) {
        return {
          ...block,
          ...blockUpdate,
        };
      }
    }

    return block;
  });
  await set(projectAtom, { ...projectNew });

  // Update the block in state -- block family atom
  blocks.forEach((block) => {
    const blockAtom = blockFamilyAtom(block._id);
    const blockValue = get(blockAtom);

    set(blockAtom, {
      ...blockValue,
      ...block,
    });
  });

  // Update the block in the backend
  // TODO: handle rollback/error state for updating blocks -- https://linear.app/dittowords/issue/DIT-8027/add-support-for-error-state-and-rollback-when-updating-blocks
  const updatedBlocks = await updateBlocks(projectId, blocks);

  return updatedBlocks;
});

export const renameBlockActionAtom = atom(null, async (get, set, blockId: string, newName: string) => {
  set(updateBlocksActionAtom, [{ _id: blockId, name: newName }]);
});
