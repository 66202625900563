import { projectNameAtom } from "@/stores/Project";
import { useAtomValue } from "jotai";
import { Suspense } from "react";
import style from "./style.module.css";

function NavBar() {
  return (
    <div className={style.wrapper}>
      <Suspense fallback={<span className={style.title}> LOADING STATE</span>}>
        <ProjectName />
      </Suspense>
    </div>
  );
}

function ProjectName() {
  const projectName = useAtomValue(projectNameAtom);

  return (
    <>
      <span className={style.title}>{projectName}</span>
    </>
  );
}

export default NavBar;
