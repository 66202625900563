import { IFChangeItem } from "@shared/types/ActualChange";
import { IFDittoProjectBlock, IFDittoProjectBlockUpdate, ZTextItemsUpdate } from "@shared/types/DittoProject";
import {
  ICreateBlocksProps,
  ICreateTextItemsProps,
  IFDittoProjectData,
  IMoveTextItemsAction,
} from "@shared/types/http/DittoProject";
import { IFTextItem, ITextItemPopulatedComments, ZTextItemStatus } from "@shared/types/TextItem";
import { z } from "zod";
import { createHttpRequest } from "./lib/createHttpRequest";

export const updateTextItems = createHttpRequest<{
  projectId: string;
  updates: z.infer<typeof ZTextItemsUpdate>[];
}>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ updates }) {
    return {
      data: { updates },
    };
  },
});

export const getProject = createHttpRequest<
  { projectId: string; projectContentSearchQuery?: string; statuses?: string[]; tags?: string[]; assignees?: string[] },
  IFDittoProjectData
>({
  method: "get",
  getUrl({ projectId, projectContentSearchQuery, statuses, tags, assignees }) {
    let url = `/ditto-project/${projectId}`;
    const searchParams = new URLSearchParams();
    if (projectContentSearchQuery) {
      searchParams.append("search", projectContentSearchQuery);
    }

    if (statuses && statuses.length > 0) {
      // coerce the status filters from the params to match our ITextItemStatus enum
      statuses
        .filter((status) => ZTextItemStatus.safeParse(status).success)
        .forEach((status) => searchParams.append("statuses[]", status));
    }

    if (tags && tags.length > 0) {
      tags.forEach((tag) => searchParams.append("tags[]", tag));
    }

    if (assignees && assignees.length > 0) {
      assignees.forEach((assignee) => searchParams.append("assignees[]", assignee));
    }

    if (searchParams.toString()) {
      url += `?${searchParams.toString()}`;
    }

    return url;
  },
});

export const getTextItems = createHttpRequest<{ ids: string[]; projectId: string }, ITextItemPopulatedComments[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});

export const createTextItems = createHttpRequest<
  { projectId: string; textItems: ICreateTextItemsProps },
  { textItems: ITextItemPopulatedComments[]; block?: IFDittoProjectBlock }
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ textItems }) {
    return {
      data: {
        textItems,
      },
    };
  },
});

export const deleteTextItem = createHttpRequest<{ projectId: string; textItemId: string }, IFTextItem[]>({
  method: "delete",
  getUrl({ projectId, textItemId }) {
    return `/ditto-project/${projectId}/text-items/${textItemId}`;
  },
});

export const getBlocks = createHttpRequest<{ ids: string[]; projectId: string }, IFDittoProjectBlock[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});

export const createBlocks = createHttpRequest<
  { projectId: string; blocks: ICreateBlocksProps; atIndex?: number },
  IFDittoProjectBlock[]
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ blocks, atIndex }) {
    return {
      data: {
        blocks,
        atIndex,
      },
    };
  },
});

export const deleteBlocks = createHttpRequest<{ projectId: string; blockIds: string[] }, void>({
  method: "delete",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ blockIds }) {
    return {
      data: { blockIds },
    };
  },
});

export const getActivity = createHttpRequest<{ projectId: string; page: number; pageSize: number }, IFChangeItem[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/changes/project/${projectId}`;
  },
  getConfig({ page, pageSize }) {
    return {
      params: { skip: page * pageSize, limit: pageSize },
    };
  },
});

export const reorderTextItems = createHttpRequest<{ projectId: string; actions: IMoveTextItemsAction[] }, void>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/move-text-items`;
  },
  getConfig({ actions }) {
    return {
      data: { actions },
    };
  },
});

export const getAllTags = createHttpRequest<{ projectId: string }, string[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/tags`;
  },
  getConfig({ projectId }) {
    return {
      params: { projectId },
    };
  },
});

export const updateBlocks = createHttpRequest<
  { projectId: string; blocks: IFDittoProjectBlockUpdate[] },
  IFDittoProjectBlock[]
>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ blocks }) {
    return {
      data: {
        blocks,
      },
    };
  },
});

export const reorderBlocks = createHttpRequest<{ projectId: string; blockIds: string[]; newIndex: number }>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks/reorder`;
  },
  getConfig({ blockIds, newIndex }) {
    return {
      data: {
        blockIds,
        newIndex,
      },
    };
  },
});
