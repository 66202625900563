import * as httpDittoProject from "@/http/dittoProject";
import { normalizeMoveAction } from "@/utils/normalizeMoveAction";
import { EMPTY_RICH_TEXT } from "@shared/frontend/constants";
import { serializeTipTapRichText } from "@shared/frontend/richText/serializer";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import { RESET } from "@shared/frontend/stores/symbols";
import { isDiffRichText } from "@shared/lib/text";
import { IFDittoProjectBlock } from "@shared/types/DittoProject";
import { IFDittoBlockData, IFDittoProjectData, IMoveTextItemsAction } from "@shared/types/http/DittoProject";
import { ITextItemPopulatedComments, ITipTapRichText } from "@shared/types/TextItem";
import logger from "@shared/utils/logger";
import ObjectId from "bson-objectid";
import { atom } from "jotai";
import { unwrap } from "jotai/utils";
import { generateKeyBetween } from "../../util/fractionalIndexing";
import { discardChangesActionAtom, discardNewTextItemActionAtom, modalAtom } from "./Modals";
import {
  blockFamilyAtom,
  projectAtom,
  projectBlocksAtom,
  projectIdAtom,
  textItemFamilyAtom,
  workspaceIdAtom,
} from "./Project";
import {
  clearSelectionActionAtom,
  selectedBlockIdAtom,
  selectedItemsAtom,
  setSelectedBlockIdsActionAtom,
  setSelectedTextIdsActionAtom,
} from "./ProjectSelection";
import { showToastActionAtom } from "./Toast";

// MARK: - Source Atoms

type InlineEditingState =
  | { type: "existing"; id: string; richText: ITipTapRichText }
  | { type: "new"; blockId: string | null; richText: ITipTapRichText }
  | null;

const _inlineEditingAtom = atom<InlineEditingState>(null);

// initial edit state
const _initialInlineEditAtom = atom<InlineEditingState>(null);

export const inlineEditingAtom = atom(
  (get) => get(_inlineEditingAtom),
  (get, set, value: InlineEditingState | typeof RESET) => {
    if (value === RESET) {
      // clear inline edit state and exit early if we're resetting atom
      set(_inlineEditingAtom, null);
      return;
    }

    const currEditingState = get(_inlineEditingAtom);

    // set our initial state when we have a new edit value, and our current edit state has not yet been set
    if (!currEditingState && value) set(_initialInlineEditAtom, value);

    // we don't track updates to edit state of type "new", so it defaults to having text changes
    // otherwise, compare difference in rich text b/w current edit state and initial edit state
    const hasTextChanges =
      currEditingState?.type === "new" ||
      isDiffRichText(get(_initialInlineEditAtom)?.richText, currEditingState?.richText);

    // conditions to trigger modals when editing:
    // 1) we're setting edit value to null
    // 2) we're overriding existing edit state
    // 3) there's been a change in text state, from our initial edit value
    // 4) modal is not already open
    if (value === null && currEditingState && hasTextChanges && !get(modalAtom)) {
      if (currEditingState.type === "existing") {
        set(modalAtom, {
          headline: "Discard unsaved text item changes?",
          content: "Your changes will be discarded. This can't be undone.",
          actionText: "Discard",
          onAction: () => set(discardChangesActionAtom),
          onOpenChange: (open) => {
            // cleanup state here when modal is closing (open === false)
            if (open) return;
            set(modalAtom, null);
            // selection stays same after cancelling edit
            set(setSelectedTextIdsActionAtom, [currEditingState.id]);
          },
        });
      } else if (currEditingState.type === "new") {
        set(modalAtom, {
          headline: "Discard new text item?",
          content: "Your changes will be discarded. This can't be undone.",
          actionText: "Discard",
          onAction: () => set(discardNewTextItemActionAtom),
          onOpenChange: (open) => {
            // cleanup state here when modal is closing (open === false)
            if (open) return;
            set(modalAtom, null);
            // don't call clearSelectionActionAtom, we don't want to clear inlineEditing state
            set(selectedItemsAtom, { type: "none" });
          },
        });
      }
      return;
    }

    set(_inlineEditingAtom, value);
  }
);

export const newBlockAtom = atom<{ name: string; atIndex?: number } | null>(null);

export const isDeletingTextItemAtom = atom(false);

export const blockDetailsEditStateAtom = atom<IFDittoProjectBlock | null>(null);

// MARK: - Derived Atoms

const selectedBlockAtom = atom(async (get) => {
  const blockId = get(selectedBlockIdAtom);
  if (!blockId) return null;

  const blockAtom = blockFamilyAtom(blockId);
  return await get(blockAtom);
});

const {
  valueAtom: _editableBlockAtom,
  resetAtom: _editableBlockResetAtom,
  hasChanged: editableBlockHasChangesAtom,
} = asyncMutableDerivedAtom({
  loadData: async (get) => get(selectedBlockAtom),
});

export { editableBlockHasChangesAtom };

type EditableBlock = IFDittoProjectBlock | null;

export const updateEditableBlockAtom = atom(
  null,
  async (get, set, value: EditableBlock | ((prev: EditableBlock) => EditableBlock) | typeof RESET) => {
    if (value === RESET) {
      set(_editableBlockResetAtom);
      return;
    }

    const newValue = typeof value === "function" ? value(await get(_editableBlockAtom)) : value;
    set(_editableBlockAtom, newValue);
  }
);

export const unwrappedEditableBlockAtom = unwrap(
  _editableBlockAtom,
  (prev) =>
    prev ?? {
      _id: "",
      name: "Loading",
      frameCount: 0,
    }
);

// MARK: - Actions

export const addNewTextItemActionAtom = atom(null, (get, set, blockId: string | null = null) => {
  set(clearSelectionActionAtom);
  set(inlineEditingAtom, { type: "new", blockId, richText: EMPTY_RICH_TEXT });
});

export const cancelNewTextItemActionAtom = atom(null, (get, set) => {
  set(inlineEditingAtom, null);
});

export const updateTextActionAtom = atom(null, async (get, set, textItemId: string, richText: ITipTapRichText) => {
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");
  const workspaceId = get(workspaceIdAtom);
  if (!workspaceId) throw new Error("workspceIdAtom is not set");

  const { text } = serializeTipTapRichText(richText);

  const textItemAtom = textItemFamilyAtom(textItemId);

  const oldRichText = (await get(textItemAtom)).rich_text;
  const { text: oldText } = serializeTipTapRichText(oldRichText);

  set(textItemAtom, (prev) => ({ ...prev, rich_text: richText, text }));

  try {
    const [request] = httpDittoProject.updateTextItems({
      projectId,
      updates: [{ textItemIds: [textItemId], richText }],
    });

    await request;
  } catch (e) {
    set(showToastActionAtom, { message: "Something went wrong updating this text" });
    logger.error(`Error updating text item with id ${textItemId}}`, { context: { projectId } }, e);
    set(textItemAtom, (prev) => ({ ...prev, rich_text: oldRichText, text: oldText }));
  }
});

export const saveNewTextItemActionAtom = atom(null, async (get, set, richText: ITipTapRichText) => {
  const inlineEditingState = get(inlineEditingAtom);
  if (inlineEditingState?.type !== "new") throw new Error("newTextItemAtom is not set");
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");
  const workspaceId = get(workspaceIdAtom);
  if (!workspaceId) throw new Error("workspceIdAtom is not set");

  const newTextItemId = new ObjectId().toString();
  const { text } = serializeTipTapRichText(richText);

  const newTextItemPlaceholder: ITextItemPopulatedComments = {
    _id: newTextItemId,
    assignee: null,
    assignedAt: null,
    text,
    rich_text: richText,
    workspace_ID: workspaceId,
    doc_ID: projectId,
    status: "NONE",
    tags: [],
    apiID: null,
    variables: [],
    plurals: [],
    notes: null,
    in_graveyard: false,
    graveyard_apiID: null,
    has_conflict: false,
    ws_comp: null,
    comment_threads: [],
    variants: [],
    integrations: {},
    lastSync: null,
    text_last_modified_at: new Date(),
    characterLimit: null,
    figma_node_ID: null,
    figma_node_ID_cached: null,
    lastSyncRichText: null,
    date_time_created: new Date(),
    is_hidden: false,
    isSample: false,
    version: 2,
  };

  // First, optimistically update the UI with estimated data
  textItemFamilyAtom(newTextItemId, newTextItemPlaceholder);

  const optimisticBlockTextItem = await set(addTextItemToBlockActionAtom, {
    blockId: inlineEditingState.blockId,
    textItemId: newTextItemId,
  });
  set(inlineEditingAtom, RESET);
  // select newly saved text item
  set(setSelectedTextIdsActionAtom, [newTextItemId]);

  let textItem: ITextItemPopulatedComments;

  try {
    // Now actually commit the change
    const [request] = httpDittoProject.createTextItems({
      projectId,
      textItems: [
        {
          _id: newTextItemId,
          richText,
          blockId: inlineEditingState.blockId,
        },
      ],
    });

    const response = await request;
    textItem = response.data.textItems[0];
  } catch (e) {
    set(showToastActionAtom, { message: "Something went wrong adding this text" });
    logger.error(`Error saving new text item`, { context: { projectId } }, e);

    // Roll back the changes in the UI
    textItemFamilyAtom.remove(newTextItemId);
    set(setSelectedTextIdsActionAtom, []);

    await set(projectBlocksAtom, (prevProjectBlocks) =>
      rollbackTextItemAddition(prevProjectBlocks, inlineEditingState.blockId, newTextItemId)
    );
    return;
  }

  set(textItemFamilyAtom(textItem._id), textItem);

  // If the real textItem has a different sortKey, we need to update it everywhere
  if (textItem.sortKey && textItem.sortKey !== optimisticBlockTextItem.sortKey) {
    const blockTextItem = { _id: newTextItemId, sortKey: textItem.sortKey };
    await set(projectBlocksAtom, (prevProjectBlocks) =>
      updateTextItem(prevProjectBlocks, textItem.blockId, blockTextItem)
    );
  }
});

const addTextItemToBlockActionAtom = atom(
  null,
  async (get, set, { blockId, textItemId }: { blockId: string | null | undefined; textItemId: string }) => {
    const blocks = await get(projectBlocksAtom);
    const blockIndex = blocks.findIndex((block) => block._id === blockId);
    const blockTextItem = { _id: textItemId, sortKey: "" };

    if (blockIndex === -1) {
      blockTextItem.sortKey = generateKeyBetween(null, null);

      // If no matching block is found, create a new block with this text item
      const updatedBlocks = blocks.concat({
        _id: blockId ?? null,
        name: "New Block",
        allTextItems: [blockTextItem],
        textItems: [blockTextItem],
      });
      await set(projectBlocksAtom, updatedBlocks);
      return blockTextItem;
    } else {
      // Add the text item to the end of the found block
      const updatedBlocks = [...blocks];
      const blockToUpdate = updatedBlocks[blockIndex];
      const lastItemSortKey = blockToUpdate.allTextItems.at(-1)?.sortKey || null;
      blockTextItem.sortKey = generateKeyBetween(lastItemSortKey, null);

      updatedBlocks[blockIndex] = {
        ...blockToUpdate,
        textItems: blockToUpdate.textItems.concat(blockTextItem),
        allTextItems: blockToUpdate.allTextItems.concat(blockTextItem),
      };

      await set(projectBlocksAtom, updatedBlocks);
      return blockTextItem;
    }
  }
);

function updateTextItem(
  blocks: IFDittoBlockData[],
  blockId: string | null | undefined,
  blockTextItem: { _id: string; sortKey: string }
): IFDittoBlockData[] {
  const blockIndex = blocks.findIndex((block) => block._id === blockId);
  if (blockIndex === -1) {
    // If we didn't find the block, we can't update anything
    return blocks;
  } else {
    const replaceItem = (item: { _id: string; sortKey: string }) => {
      if (item._id === blockTextItem._id) {
        return blockTextItem;
      } else {
        return item;
      }
    };

    const updatedBlocks = [...blocks];
    updatedBlocks[blockIndex] = {
      ...blocks[blockIndex],
      textItems: blocks[blockIndex].textItems.map(replaceItem),
      allTextItems: blocks[blockIndex].allTextItems.map(replaceItem),
    };

    return updatedBlocks;
  }
}

function rollbackTextItemAddition(
  blocks: IFDittoBlockData[],
  blockId: string | null | undefined,
  textItemId: string
): IFDittoBlockData[] {
  const blockIndex = blocks.findIndex((block) => block._id === blockId);
  if (blockIndex === -1) {
    // If no matching block, just return unedited. This is unexpected.
    return blocks;
  } else {
    // Remove the text item by id from each list
    const updatedBlocks = [...blocks];
    const blockToUpdate = updatedBlocks[blockIndex];
    updatedBlocks[blockIndex] = {
      ...blockToUpdate,
      textItems: blockToUpdate.textItems.filter((textItem) => textItem._id !== textItemId),
      allTextItems: blockToUpdate.allTextItems.filter((textItem) => textItem._id !== textItemId),
    };
    return updatedBlocks;
  }
}

function moveTextItem(
  listKey: "allTextItems" | "textItems",
  props: {
    project: IFDittoProjectData;
    oldBlockIndex: number;
    newBlockIndex: number;
    textItemId: string;
    action: IMoveTextItemsAction;
    referenceTextItemId?: string;
  }
) {
  const oldBlockPosition = props.project.blocks[props.oldBlockIndex][listKey].findIndex(
    (item) => item._id === props.textItemId
  );
  if (oldBlockPosition === -1) throw new Error("TextItem not found in old block");
  const existingTextItem = props.project.blocks[props.oldBlockIndex][listKey].splice(oldBlockPosition, 1)[0];

  let positionToInsert = props.project.blocks[props.newBlockIndex][listKey].length;
  if (props.referenceTextItemId) {
    const referenceTextItemInList = props.project.blocks[props.newBlockIndex][listKey].findIndex(
      (item) => item._id === props.referenceTextItemId
    );
    if (referenceTextItemInList === -1) throw new Error("Reference TextItem not found in new block");
    if (props.action.before) positionToInsert = referenceTextItemInList;
    if (props.action.after) positionToInsert = referenceTextItemInList + 1;
  }

  props.project.blocks[props.newBlockIndex][listKey].splice(positionToInsert, 0, existingTextItem);
}

export const reorderTextItemsActionAtom = atom(null, async (get, set, actions: IMoveTextItemsAction[]) => {
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");

  const oldProject = await get(projectAtom);
  const project = { ...oldProject };

  const fixedActions = actions.map((action) => {
    const actionDestinationBlock = project.blocks.find((block) => block._id === action.blockId);

    if (!actionDestinationBlock) {
      throw new Error("Block not found");
    }

    const blockItems = actionDestinationBlock.textItems.map((x) => x._id);

    const direction = action.before ? "before" : "after";

    const normalized = normalizeMoveAction({
      itemIds: action.textItemIds,
      referenceItemId: action.before ?? action.after ?? undefined,
      direction,
      itemIdsInCollection: blockItems,
      allItemIds: project.blocks.flatMap((block) => block.textItems.map((x) => x._id)),
    });

    return {
      ...action,
      textItemIds: normalized.itemIds,
      before: normalized.direction === "before" ? normalized.referenceItemId : undefined,
      after: normalized.direction === "after" ? normalized.referenceItemId : undefined,
    };
  });

  for (const action of fixedActions) {
    const referenceTextItemId = action.before ?? action.after;
    for (const textItemId of action.textItemIds) {
      // Get the actual text item data to get its block.
      const textItemAtom = textItemFamilyAtom(textItemId);
      const textItem = await get(textItemAtom);

      // Find the indexes of the old and new blocks.
      const oldBlockIndex = project.blocks.findIndex((block) => block._id === (textItem.blockId ?? null));
      const newBlockIndex = project.blocks.findIndex((block) => block._id === (action.blockId ?? null));
      if (oldBlockIndex === -1) throw new Error("Old block not found");
      if (newBlockIndex === -1) throw new Error("New block not found");

      // Get the reference id in the new block
      const referenceTextItemIdInAllTextItems = project.blocks[newBlockIndex].allTextItems.findIndex(
        (item) => item._id === referenceTextItemId
      );
      if (referenceTextItemIdInAllTextItems === -1 && referenceTextItemId)
        throw new Error("Reference TextItem not found in new block");

      // Handle moving the text item in allTextItems array from old block location to new block location.
      moveTextItem("allTextItems", {
        project,
        oldBlockIndex,
        newBlockIndex,
        textItemId,
        action,
        referenceTextItemId,
      });
      // Handle moving the text item in filtered textItems array from old block location to new block location.
      moveTextItem("textItems", {
        project,
        oldBlockIndex,
        newBlockIndex,
        textItemId,
        action,
        referenceTextItemId,
      });

      // Update the local textItem data
      set(textItemAtom, {
        ...textItem,
        blockId: project.blocks[newBlockIndex]._id,
      });
    }
  }

  project.blocks = project.blocks.map((block) => ({ ...block }));
  await set(projectAtom, project);

  const [request] = httpDittoProject.reorderTextItems({
    projectId: projectId,
    actions: fixedActions,
  });

  try {
    await request;
  } catch (error) {
    logger.error("Error persisting reordering text items", { context: { actions: fixedActions } }, error);
    throw error;
    // TODO: Add support for error handling and rolling back optimistic updates
    // see https://linear.app/dittowords/issue/DIT-8005/add-support-for-error-handling-and-optimistic-update-rollbacks-when
  }
});

export const deleteTextItemActionAtom = atom(null, async (get, set, textItemId: string) => {
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");

  const itemToDelete = await get(textItemFamilyAtom(textItemId));

  // `blockId` _should_ always be null, but this guards against bad data
  const blockId = itemToDelete.blockId ?? null;

  // perform optimistic delete on project state
  const updatedProject = { ...(await get(projectAtom)) };
  const block = updatedProject.blocks.find((block) => block._id === blockId);
  if (!block) {
    return;
  }

  // need to compute the indices before the text item is removed from the block arrays
  // just in case we need to revert the state change
  const textItemsDeleteIdx = block.textItems.findIndex((textItem) => textItem._id === itemToDelete._id);
  const allTextItemsDeleteIdx = block.allTextItems.findIndex((textItem) => textItem._id === itemToDelete._id);

  // ensure that the block arrays are given new text item array references
  block.textItems = block.textItems.filter((textItem) => textItem._id !== itemToDelete._id);
  block.allTextItems = block.allTextItems.filter((textItem) => textItem._id !== itemToDelete._id);

  await set(projectAtom, updatedProject);

  try {
    const [request] = httpDittoProject.deleteTextItem({ projectId: projectId, textItemId: itemToDelete._id });
    await request;
  } catch (e) {
    set(showToastActionAtom, { message: "Something went wrong deleting this text" });
    logger.error(`Error deleting text item with id ${itemToDelete._id}}`, { context: { blockId } }, e);

    // need to re-fetch the project because some other mechanism on the page (such as websockets or other actions) might've
    // made unrelated changes to the project state between the time the state update was applied and the time that the
    // delete request failed
    const updatedProject = { ...(await get(projectAtom)) };
    const block = updatedProject.blocks.find((block) => block._id === blockId);
    if (!block) {
      // this means that the block itself has been deleted since an attempt was made to delete the text item
      logger.warn("Block not found in project state after failed text item delete", { context: { blockId } });
      return;
    }

    // reset state, ensuring that the block arrays are given new text item array references
    const restoredItem = {
      _id: itemToDelete._id,
      sortKey: itemToDelete.sortKey ?? "",
    };

    if (textItemsDeleteIdx > block.textItems.length - 1) {
      // the items arr has shrunk by one, so in case where deleted item was last element, push it onto end of items
      block.textItems.push(restoredItem);
    } else {
      block.textItems = block.textItems.flatMap((textItem, index) =>
        index === textItemsDeleteIdx ? [restoredItem, textItem] : [textItem]
      );
    }

    if (allTextItemsDeleteIdx > block.allTextItems.length - 1) {
      // the items arr has shrunk by one, so in case where deleted item was last element, push it onto end of items
      block.allTextItems.push(restoredItem);
    } else {
      block.allTextItems = block.allTextItems.flatMap((textItem, index) =>
        index === allTextItemsDeleteIdx ? [restoredItem, textItem] : [textItem]
      );
    }

    await set(projectAtom, updatedProject);
  }
});

export const addNewBlockActionAtom = atom(null, (get, set, update?: { name?: string; atIndex?: number }) => {
  const name = update?.name ?? "";

  set(newBlockAtom, { name, atIndex: update?.atIndex });
  set(clearSelectionActionAtom);
});

export const changeNewBlockNameActionAtom = atom(null, (get, set, name: string) => {
  set(newBlockAtom, (prev) => (prev ? { ...prev, name } : prev));
});

export const cancelNewBlockActionAtom = atom(null, (get, set) => {
  set(newBlockAtom, null);
});

export const saveNewBlockActionAtom = atom(null, async (get, set) => {
  const newBlock = get(newBlockAtom);
  const projectId = get(projectIdAtom);
  if (!newBlock) throw new Error("newBlockAtom is not set");
  if (!projectId) throw new Error("projectIdAtom is not set");

  // Optimistically add the new block in the UI
  const newBlockId = new ObjectId().toString();

  const { name: optimisticBlockName } = await set(addBlockActionAtom, {
    _id: newBlockId,
    name: newBlock.name,
    atIndex: newBlock.atIndex,
  });
  set(newBlockAtom, null);

  // select new block after it's been added
  set(setSelectedBlockIdsActionAtom, newBlockId);

  let blockName = optimisticBlockName;

  try {
    // Create the block in the backend
    const [request] = httpDittoProject.createBlocks({
      projectId: projectId,
      blocks: [
        {
          _id: newBlockId,
          name: newBlock.name,
        },
      ],
      atIndex: newBlock.atIndex,
    });

    const response = await request;
    const blockFromBackend = response.data.find((block) => block._id === newBlockId);
    if (blockFromBackend) blockName = blockFromBackend?.name;
  } catch (e) {
    set(showToastActionAtom, { message: "Something went wrong adding this block" });
    logger.error(`Error saving new block`, { context: { projectId } }, e);
    await set(rollbackBlockCreationActionAtom, newBlockId);
  }

  if (blockName !== optimisticBlockName) {
    await set(updateBlockNameActionAtom, { _id: newBlockId, name: blockName });
  }
});

/**
 * Adds a block with the provided name and id to Jotai
 * - blockFamilyAtom
 * - projectAtom.blocks list
 *
 * Only updates Jotai, does not communicate with the backend
 */
const addBlockActionAtom = atom(
  null,
  async (get, set, { _id, name, atIndex }: { _id: string; name: string; atIndex?: number }) => {
    const oldProjectBlocks = await get(projectBlocksAtom);

    const newBlockName = name || `Block ${oldProjectBlocks.length}`;

    blockFamilyAtom(_id, {
      _id,
      name: newBlockName,
      frameCount: 0,
    });

    const blockToAdd = {
      _id,
      name: newBlockName,
      allTextItems: [],
      textItems: [],
    };

    if (atIndex !== undefined) {
      const newBlocks = [...oldProjectBlocks];
      newBlocks.splice(atIndex, 0, blockToAdd);
      await set(projectBlocksAtom, newBlocks);
    } else {
      // Add the block to the project block list
      // This list should have a block with id null at the end, for non-block text items,
      // The null block should remain at the end, so add this block right before it as long as it's present
      const lastBlock = oldProjectBlocks.at(-1);
      if (lastBlock && lastBlock._id === null) {
        await set(projectBlocksAtom, oldProjectBlocks.slice(0, -1).concat(blockToAdd, lastBlock));
      } else {
        await set(projectBlocksAtom, oldProjectBlocks.concat(blockToAdd));
      }
    }
    return { _id, name: newBlockName };
  }
);

/**
 * Updates the name of a block by _id in Jotai
 * - blockFamilyAtom
 * - projectAtom.blocks list
 *
 * Only updates Jotai, does not communicate with the backend
 */
const updateBlockNameActionAtom = atom(null, async (get, set, { _id, name }: { _id: string; name: string }) => {
  // Update the name in the block family atom
  set(blockFamilyAtom(_id), (prevBlock) => {
    if (prevBlock.name !== name) {
      return { ...prevBlock, name };
    } else {
      return prevBlock;
    }
  });

  // Update the name in the project block list
  set(projectBlocksAtom, (prevProjectBlocks) => {
    const blockIndex = prevProjectBlocks.findIndex((b) => b._id === _id);
    const blockToUpdate = prevProjectBlocks[blockIndex];

    if (blockIndex !== -1 && blockToUpdate.name !== name) {
      return prevProjectBlocks.with(blockIndex, {
        ...blockToUpdate,
        name,
      });
    } else {
      return prevProjectBlocks;
    }
  });
});

/**
 * Removes a block by id from Jotai
 * - blockFamilyAtom
 * - projectAtom.blocks list
 *
 * Only updates Jotai, does not communicate with the backend
 * This is for rolling back an optimistic update on failure to create a block
 */
const rollbackBlockCreationActionAtom = atom(null, async (get, set, blockId: string) => {
  // Remove from block family atom
  blockFamilyAtom.remove(blockId);

  // Remove from project block list
  await set(projectBlocksAtom, (prevProjectBlocks) => prevProjectBlocks.filter((b) => b._id !== blockId));
});
