import {
  addNewBlockActionAtom,
  addNewTextItemActionAtom,
  cancelNewTextItemActionAtom,
  inlineEditingAtom,
  newBlockAtom,
  saveNewTextItemActionAtom,
} from "@/stores/Editing";
import {
  nonBlockTextItemsAtom,
  projectBlocksCountAtom,
  projectBlocksSplitAtom,
  projectTextItemsCountAtom,
} from "@/stores/Project";
import { onTextItemKeyDownActionAtom } from "@/stores/ProjectSelection";
import NewBlockWrapper from "@/views/NS/ProjectNS/components/NewBlockWrapper";
import Button from "@ds/atoms/Button";
import Scrollbar from "@ds/molecules/Scrollbar";
import TextItem from "@ds/molecules/TextItem";
import AddIcon from "@mui/icons-material/Add";
import { EMPTY_RICH_TEXT } from "@shared/frontend/constants";
import { useAtomValue, useSetAtom } from "jotai";
import TextItemBlockWrapper from "../TextItemBlockWrapper";
import style from "./style.module.css";

function TextItemList() {
  const projectBlocksAtoms = useAtomValue(projectBlocksSplitAtom);
  const numTextItems = useAtomValue(projectTextItemsCountAtom);
  const numBlocks = useAtomValue(projectBlocksCountAtom);
  const addNewTextItemAction = useSetAtom(addNewTextItemActionAtom);
  const saveNewTextItemAction = useSetAtom(saveNewTextItemActionAtom);
  const cancelNewTextItemAction = useSetAtom(cancelNewTextItemActionAtom);
  const addNewBlockAction = useSetAtom(addNewBlockActionAtom);
  const onTextItemKeyDownAction = useSetAtom(onTextItemKeyDownActionAtom);
  const inlineEditingState = useAtomValue(inlineEditingAtom);
  const newBlock = useAtomValue(newBlockAtom);
  const nonBlockTextItems = useAtomValue(nonBlockTextItemsAtom);

  const isEditingNewContent = inlineEditingState?.type === "new" || !!newBlock;

  const emptyProject = numTextItems === 0 && numBlocks < 2;

  return (
    <div className={style.main} tabIndex={0} onKeyDown={onTextItemKeyDownAction}>
      <Scrollbar className={style.scrollWrapper}>
        <div className={style.textItemListContainer}>
          {emptyProject && !(inlineEditingState?.type === "new") && !newBlock && (
            <Button
              className={style.emptyProjectButton}
              variant="text"
              iconSize="xs"
              leadingIcon={<AddIcon />}
              onClick={() => addNewTextItemAction()}
            >
              Add your first text to this project
            </Button>
          )}

          {emptyProject && inlineEditingState?.type === "new" && (
            <TextItem
              defaultValue={EMPTY_RICH_TEXT}
              onClickSave={saveNewTextItemAction}
              onClickCancel={cancelNewTextItemAction}
              editState="editing"
            />
          )}

          <div className={style.blocks}>
            {projectBlocksAtoms.map((projectBlockAtom, index) => {
              const isCreatingNewBlockAfter = newBlock?.atIndex !== undefined && newBlock.atIndex === index + 1;
              const isCreatingNewBlockBefore =
                (newBlock && index === newBlock.atIndex) ||
                (newBlock && newBlock?.atIndex === undefined && index === numBlocks - 1) ||
                // this is to handle the case where atIndex is (incorrectly) not a valid block index; we default to
                // rendering the newBlock at the end of the list
                (newBlock &&
                  newBlock.atIndex !== undefined &&
                  newBlock.atIndex >= numBlocks &&
                  index === numBlocks - 1);

              // This is true if:
              // -- the block is the last actual block in the list, and
              // -- there are no non-block text items in the project
              const blockIsLastRow = index === numBlocks - 2 && nonBlockTextItems.length === 0;

              return (
                <div className={style.blockRow} key={`${projectBlockAtom}`}>
                  {isCreatingNewBlockBefore && (
                    <>
                      <NewBlockWrapper className={style.newBlock} />
                      <div className={style.betweenBlocksRow}></div>
                    </>
                  )}

                  <TextItemBlockWrapper
                    key={`${projectBlockAtom}`}
                    textItemBlockAtom={projectBlockAtom}
                    index={index}
                    maxIndex={numBlocks - 2}
                  />

                  {index < numBlocks - 1 && (
                    <div className={style.betweenBlocksRow}>
                      {!isCreatingNewBlockAfter && !blockIsLastRow && (
                        <Button
                          size="small"
                          variant="text"
                          iconSize="xs"
                          leadingIcon={<AddIcon />}
                          onClick={() => addNewBlockAction({ atIndex: index + 1 })}
                          className={style.createNewButton}
                        >
                          Create block
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={style.addItems}>
          {/* NOTE: This could be further optimized to prevent re-renders whenever adding a new tex item by moving these buttons into their own component */}
          <Button
            disabled={isEditingNewContent}
            variant="text"
            iconSize="xs"
            size="small"
            leadingIcon={<AddIcon />}
            onClick={() => addNewTextItemAction()}
          >
            Add text item
          </Button>

          <Button
            disabled={isEditingNewContent}
            variant="text"
            iconSize="xs"
            size="small"
            leadingIcon={<AddIcon />}
            onClick={() => addNewBlockAction()}
          >
            Create block
          </Button>
        </div>
      </Scrollbar>
    </div>
  );
}

export default TextItemList;
