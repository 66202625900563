import { allTagsInProject } from "@/stores/Project";
import {
  FilterKey,
  PROJECT_FILTERS,
  selectedFiltersAtomFamily,
  selectedFiltersListAtom,
} from "@/stores/ProjectFiltering";
import {
  hiddenFieldsListAtom,
  resetHiddenFieldsActionAtom,
  updateHiddenFieldsActionAtom,
} from "@/stores/TextItemMetadata";
import { usersAtom } from "@/stores/Workspace";
import Button from "@ds/atoms/Button";
import SeparatorLine from "@ds/atoms/SeparatorLine";
import AddFiltersDropdown from "@ds/molecules/AddFiltersDropdown";
import AssigneeFilterDropdown from "@ds/molecules/AssigneeFilterDropdown";
import HideFieldsDropdown from "@ds/molecules/HideFieldsDropdown";
import NavMenuItem from "@ds/molecules/NavMenuItem";
import StatusFilterDropdown from "@ds/molecules/StatusFilterDropdown";
import TagsFilterDropdown from "@ds/molecules/TagsFilterDropdown";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import FilterBarSearch from "./FilterBarSearch";
import style from "./style.module.css";

const PROJECT_FILTER_OPTIONS = Object.values(PROJECT_FILTERS).map((filter) => ({
  value: filter.value,
  label: filter.label,
}));

function FilterBar() {
  const users = useAtomValue(usersAtom);

  const [selectedFilters, setSelectedFilters] = useAtom(selectedFiltersListAtom);

  const [statusFilters, setStatusFilters] = useAtom(selectedFiltersAtomFamily("status"));
  const [assigneeFilters, setAssigneeFilters] = useAtom(selectedFiltersAtomFamily("assignee"));
  const [variantFilters, setVariantFilters] = useAtom(selectedFiltersAtomFamily("variant"));
  const [pageFilters, setPageFilters] = useAtom(selectedFiltersAtomFamily("page"));
  const [apiIDFilters, setApiIDFilters] = useAtom(selectedFiltersAtomFamily("apiID"));
  const [tagsFilters, setTagsFilters] = useAtom(selectedFiltersAtomFamily("tags"));
  const [allTags, setAllTags] = useAtom(allTagsInProject);

  const hiddenFieldsList = useAtomValue(hiddenFieldsListAtom);
  const resetHiddenFields = useSetAtom(resetHiddenFieldsActionAtom);
  const updateHiddenFields = useSetAtom(updateHiddenFieldsActionAtom);

  function onResetHiddenFields() {
    resetHiddenFields();
  }

  const selectedFilterOptions = useMemo(
    () =>
      selectedFilters.map((filterKey) => ({
        value: PROJECT_FILTERS[filterKey].value,
        label: PROJECT_FILTERS[filterKey].label,
      })),
    [selectedFilters]
  );

  function onSetSelectedFilters(newSelectedFilters: { value: FilterKey; label: string }[]) {
    setSelectedFilters(newSelectedFilters.map((filter) => filter.value));
  }

  function setHiddenFields(newSelectedOptions: { value: string; label: string }[]) {
    const selectedValuesSet = new Set(newSelectedOptions.map((option) => option.value));

    const newHiddenFields =
      hiddenFieldsList?.map((field) => {
        if (selectedValuesSet.has(field.value)) {
          return {
            ...field,
            isHidden: true,
          };
        } else {
          return {
            ...field,
            isHidden: false,
          };
        }
      }) ?? [];

    updateHiddenFields(newHiddenFields);
  }

  return (
    <div className={style.wrapper}>
      <div className={style.leftSection}>
        <HideFieldsDropdown
          hiddenFieldsList={hiddenFieldsList ?? []}
          setSelectedOptions={setHiddenFields}
          removeFilter={onResetHiddenFields}
          className={style.hideFieldsDropdown}
        />
        <NavMenuItem Icon={<CallSplitIcon className={style.rotateRight} />} label="Variant" />
        <AddFiltersDropdown
          selectedOptions={selectedFilterOptions}
          setSelectedOptions={onSetSelectedFilters}
          options={PROJECT_FILTER_OPTIONS}
        />

        {statusFilters && (
          <StatusFilterDropdown
            value={statusFilters}
            onChange={setStatusFilters}
            onRemoveFilter={() => setStatusFilters(null)}
          />
        )}

        {assigneeFilters && (
          <AssigneeFilterDropdown
            selectedUsers={assigneeFilters}
            setSelectedUsers={setAssigneeFilters}
            users={users}
            onRemoveFilter={() => setAssigneeFilters(null)}
          />
        )}
        {variantFilters && <span>Variant</span>}
        {pageFilters && <span>Page</span>}
        {apiIDFilters && <span>Developer ID</span>}
        {tagsFilters && (
          <TagsFilterDropdown
            allTags={allTags}
            selectedTags={tagsFilters}
            setSelectedTags={setTagsFilters}
            onRemoveFilter={() => setTagsFilters(null)}
          />
        )}

        {selectedFilters.length > 0 && (
          <Button variant="text" size="xs" onClick={() => setSelectedFilters([])}>
            Clear filters
          </Button>
        )}
      </div>
      <div className={style.rightSection}>
        <FilterBarSearch />
        <SeparatorLine color="var(--border-secondary)" height={20} />
        <NavMenuItem Icon={<BrokenImageOutlinedIcon />} label="Design preview" />
      </div>
    </div>
  );
}

export default FilterBar;
